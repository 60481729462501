import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import reportWebVitals from 'utils/reportWebVitals';
import Application from 'containers/Application'
import Debug from 'containers/Debug'
import EmailPassword from 'screens/EmailPassword'
import ResetPassword from 'screens/ResetPassword'
import Signin from 'screens/Signin'
import PrivateRoute from 'components/PrivateRoute'
import history from 'utils/history';
import { ProvideAuth, AUTH_GOOGLE_CLIENT_ID } from "utils/auth.js";
import ThemeProvider from 'components/ThemeProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Scanner from 'screens/Scanner'
import MyTasks from 'screens/MyTasks'
import Tasks from 'screens/Tasks'
import Task from 'screens/Task'
import AddTask from 'screens/AddTask'
import Monitor from 'screens/Monitor'
import DigitalLogbook from 'screens/DigitalLogbook'
import DigitalLogbookTask from 'screens/task/DigitalLogbookTask'
import FeedConsumptionTask from 'screens/task/FeedConsumptionTask'
import SaltConsumptionTask from 'screens/task/SaltConsumptionTask'
import ChemicalConsumptionTask from 'screens/task/ChemicalConsumptionTask'
import ProbioticConsumptionTask from 'screens/task/ProbioticConsumptionTask'
import ShrimpEvaluationTask from 'screens/task/ShrimpEvaluationTask'
import SeaweedMonitor from 'screens/SeaweedMonitor'
import Packaging from 'screens/Packaging'
import Label from 'screens/Label'
import PrintLabels from 'screens/PrintLabels'
import OrderProcessing from 'screens/OrderProcessing'
import PrintLabelList from 'screens/PrintLabelList'
import Settings from 'screens/Settings'
import UserPassword from 'screens/UserPassword'
import TraceLot from 'screens/TraceLot'
import WaterExchangeRegime from 'screens/WaterExchangeRegime'
import MoanaBrain from "screens/moanabrain/MoanaBrain"

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider>
        <GoogleOAuthProvider clientId={AUTH_GOOGLE_CLIENT_ID}>
        <ProvideAuth>
          <BrowserRouter history={history}>
            <Routes>
              <Route path='/signin/' element={<Signin />} />
              <Route path='/emailpassword/' element={<EmailPassword />} />
              <Route path='/resetpassword/' element={<ResetPassword />} />
              <Route path='/debug/' element={<Debug />} />
              <Route path='/app//*' element={<PrivateRoute><Application /></PrivateRoute>}>
                <Route path='' element={<Navigate from='/app/' to='/app/tasks/' />} />
                <Route path='task/add/' element={<AddTask />} />
                <Route path='task/digitallogbook/add/' element={<DigitalLogbookTask />} />
                <Route path='task/feedconsumption/add/' element={<FeedConsumptionTask />} />
                <Route path='task/saltconsumption/add/' element={<SaltConsumptionTask />} />
                <Route path='task/chemicalconsumption/add/' element={<ChemicalConsumptionTask />} />
                <Route path='task/probioticconsumption/add/' element={<ProbioticConsumptionTask />} />
                <Route path='task/shrimpevaluation/:id/' element={<ShrimpEvaluationTask />} />
                <Route path='task/:id/' element={<Task />} />
                <Route path='mytasks/:id/' element={<MyTasks />} />
                <Route path='mytasks/' element={<MyTasks />} />
                <Route path='tasks/' element={<Tasks/>} />
                <Route path='settings/' element={<Settings />} />
                <Route path='password/' element={<UserPassword />} />
                <Route path='scanner/' element={<Scanner />} />
                <Route path='logbook/' element={<DigitalLogbook />} />
                <Route path='monitor/seaweed/' element={<SeaweedMonitor />} />
                <Route path='monitor/' element={<Monitor />} />
                <Route path='packaging/:date?' element={<Packaging />} />
                <Route path='label/:date?' element={<Label />} />
                <Route path='pdf-labels/:date?' element={<PrintLabels />} />
                <Route path='pdf-label-list/:date?' element={<PrintLabelList />} />
                <Route path='order/' element={<OrderProcessing />} />
                <Route path='trace-lot/' element={<TraceLot />} />
                <Route path='water-exchange-regime/' element={<WaterExchangeRegime />} />
                <Route path='moana-brain-reduced/' element={<MoanaBrain />} />
              </Route>
              <Route path='/' element={<Navigate to='/app/' />} />
            </Routes>
          </BrowserRouter>
        </ProvideAuth>
      </GoogleOAuthProvider>
    <ToastContainer />
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
